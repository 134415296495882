import React, { useEffect, useState } from 'react';
import CoverImage from '../common/CoverImage';
import Address from './Address';
import EventDetail from './EventDetail';
import Organizer from './Organizer';
import EventHeader from './EventHeader';
import EventCategories from './EventCategories';
import TargetAudience from './TargetAudience';
import {
  clearCurrentEvent,
  retrieveEventbyId,
  setCoverImage,
} from '../../store/actions/currentEvent';
import { useDispatch, useSelector } from 'react-redux';
import EventDate from './EventDate';
import { useParams } from 'react-router';
import EventButtons from './EventButtons';
import EventLanguage from './EventLanguage';

const EventForm = () => {
  const dispatch = useDispatch();
  const languageSelector = useSelector((state) => state.currentEvent.language);
  const selectedCoverImage = useSelector(
    (state) => state.currentEvent.coverImage[languageSelector]
  );
  const [dateError, setDateError] = useState('');

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(retrieveEventbyId(id));
    } else {
      dispatch(clearCurrentEvent());
    }
  }, [dispatch, id]);
  return (
    <div>
      <EventHeader />
      <CoverImage
        setCoverImage={setCoverImage}
        selectedCoverImage={selectedCoverImage}
      />
      <Address />
      <EventDetail />
      <TargetAudience />
      <EventLanguage />
      <EventDate setDateError={setDateError} />
      <Organizer />
      <EventCategories />
      <EventButtons dateError={dateError} />
    </div>
  );
};

export default EventForm;
