import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { WithHeader } from './WithHeader';
import { useDispatch, useSelector } from 'react-redux';
import { setContent } from '../../store/actions/currentArticle';
import { localizedContentSelector } from '../../store/reducers/selector';

const SupportingContent = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const localizedContent = useSelector(localizedContentSelector);
  const HandleContent = (event) => {
    const content = event.target.value;
    dispatch(setContent(content));
    if (content.length >= 1000) {
      setError(true);
    } else {
      setError(false);
    }
  };
  return (
    <div>
      <TextField
        placeholder="Start typing to add supporting content to the video..."
        multiline
        rows={4}
        onChange={HandleContent}
        value={localizedContent}
        variant="outlined"
        inputProps={{ maxLength: 1000 }}
        sx={{ width: '100%' }}
        error={error}
        helperText={error ? 'Maximum length of 1000 characters exceeded.' : ''}
      />
    </div>
  );
};

export default WithHeader(SupportingContent, 'Supporting Content');
