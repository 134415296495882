import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DriveFolderUploadRoundedIcon from "@mui/icons-material/DriveFolderUploadRounded";
import { Badge, CircularProgress, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { setThumbnail } from "../../store/actions/currentArticle";
import BlobService from "../../services/blob-service";
import { WithHeader } from "./WithHeader";
import Resizer from "react-image-file-resizer";

const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20 MB in bytes

const CoverImage = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.article.language);
  const selectedFile = useSelector((state) => state.article.thumbnail[lang]);
  const [loading, setLoading] = useState(false);
  const maxWidth = 275;
  const maxHeight = 183;

  const [error, setError] = useState(null);

  // const handleFileSelect = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const maxWidth = 275; // maximum width
  //     const maxHeight = 183; // maximum height
  //     const allowedFormats = ["image/jpeg", "image/png", "image/jpg"];

  //     if (file.size > MAX_FILE_SIZE) {
  //       setError(
  //         "File size exceeds the limit. Please select a file within 20 MB."
  //       );
  //     } else if (!allowedFormats.includes(file.type)) {
  //       setError("Please select a JPG/JPEG/PNG file.");
  //     } else {
  //       const filename = BlobService.generateFileName(file);

  //       // Check if resizing is necessary based on image dimensions
  //       const img = new Image();
  //       img.onload = function () {
  //         const width = this.width;
  //         const height = this.height;

  //         if (width > maxWidth || height > maxHeight) {
  //           console.log("Resizing:");
  //           // Resize the image before uploading
  //           Resizer.imageFileResizer(
  //             file,
  //             maxWidth,
  //             maxHeight,
  //             file.type.split("/")[1], // format
  //             100, // quality
  //             0, // rotation
  //             (resizedFile) => {
  //               setLoading(true);
  //               BlobService.uploadImage(filename, resizedFile).then(() => {
  //                 setError(null);
  //                 setLoading(false);
  //                 dispatch(setThumbnail(filename));
  //               });
  //             },
  //             "blob" // output type
  //           );
  //         } else {
  //           setLoading(true);
  //           BlobService.uploadImage(filename, file).then(() => {
  //             setError(null);
  //             setLoading(false);
  //             dispatch(setThumbnail(filename));
  //           });
  //         }
  //       };
  //       img.src = URL.createObjectURL(file);
  //     }
  //   }
  // };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setError(
          "File size exceeds the limit. Please select a file within 20 MB."
        );
      } else if (
        file.type !== "image/jpeg" &&
        file.type !== "image/png" &&
        file.type !== "image/jpg"
      ) {
        setError("Please select a JPG/JPEG/PNG file.");
      } else {
        const filename = BlobService.generateFileName(file);
        setLoading(true);
        BlobService.uploadImage(filename, file).then(() => {
          setError(null);
          setLoading(false);
          dispatch(setThumbnail(filename));
        });
      }
    }
  };

  const deleteFile = () => {
    // BlobService.deleteImage(selectedFile).then(() => {
    // setSelectedFile(null);
    dispatch(setThumbnail(""));
    // });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];

    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setError(
          "File size exceeds the limit. Please select a file within 20 MB."
        );
      } else if (
        file.type !== "image/jpeg" &&
        file.type !== "image/png" &&
        file.type !== "image/jpg"
      ) {
        setError("Please select a JPG/JPEG/PNG file.");
      } else {
        const filename = BlobService.generateFileName(file);
        setLoading(true);
        BlobService.uploadImage(filename, file).then(() => {
          setError(null);
          setLoading(false);
          dispatch(setThumbnail(filename));
        });
      }
    }
  };

  // const handleDrop = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   const file = e.dataTransfer.files[0];
  //   const allowedFormats = ["image/jpeg", "image/png", "image/jpg"];

  //   if (file) {
  //     if (file.size > MAX_FILE_SIZE) {
  //       setError(
  //         "File size exceeds the limit. Please select a file within 20 MB."
  //       );
  //     } else if (!allowedFormats.includes(file.type)) {
  //       setError("Please select a JPG/JPEG/PNG file.");
  //     } else {
  //       const filename = BlobService.generateFileName(file);

  //       // Check if resizing is necessary based on image dimensions
  //       const img = new Image();
  //       img.onload = function () {
  //         const width = this.width;
  //         const height = this.height;

  //         if (width > maxWidth || height > maxHeight) {
  //           console.log("Resizing:");
  //           // Resize the image before uploading
  //           Resizer.imageFileResizer(
  //             file,
  //             maxWidth,
  //             maxHeight,
  //             file.type.split("/")[1], // format
  //             100, // quality
  //             0, // rotation
  //             (resizedFile) => {
  //               setLoading(true);
  //               BlobService.uploadImage(filename, resizedFile).then(() => {
  //                 setError(null);
  //                 setLoading(false);
  //                 dispatch(setThumbnail(filename));
  //               });
  //             },
  //             "blob" // output type
  //           );
  //         } else {
  //           setLoading(true);
  //           BlobService.uploadImage(filename, file).then(() => {
  //             setError(null);
  //             setLoading(false);
  //             dispatch(setThumbnail(filename));
  //           });
  //         }
  //       };
  //       img.src = URL.createObjectURL(file);
  //     }
  //   }
  // };

  //Thumbnail message
  // We will resize and adjust large-sized pictures for better UI.
  // However, we cannot resize small pictures.

  return (
    <>
      {" "}
      {loading ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{
            border: 1,
            borderColor: "grey.500",
            height: 300,
            position: "relative",
          }}
        >
          <CircularProgress
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
          {/* <Typography
            style={{
              position: 'absolute',
              left: '53%',
              top: '53%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            {`${PercentCompleted}%`}
          </Typography> */}
        </Box>
      ) : selectedFile ? (
        <Box display="flex" flexDirection="column" alignItems={"center"}>
          <Badge badgeContent="X" color="secondary" onClick={deleteFile}>
            <img
              src={BlobService.getImageUri(selectedFile)}
              style={{ height: "15em" }}
              alt="Selected file"
            />
          </Badge>
        </Box>
      ) : (
        <Box
          display="flex"
          sx={{ m: 5 }}
          flexDirection="column"
          alignItems={"center"}
          justifyContent="center"
          textAlign="center"
        >
          {" "}
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
          >
            <input
              hidden
              accept=".jpeg, .png, .jpg"
              type="file"
              onChange={handleFileSelect}
            />
            <DriveFolderUploadRoundedIcon
              style={{ fontSize: "4em", color: "#D9D9D9" }}
            />
          </IconButton>
          <Box
            display="flex"
            marginTop="1rem"
            marginBottom="1rem"
            padding="3rem"
            border="dashed 1px #ccc"
            alignItems={"center"}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <Typography variant="caption">
              drag and drop the image here or upload manually. <br />
            </Typography>
          </Box>
          <Typography variant="caption">
            Max File Size: 20 MB <br />
            Recommended image resolution (360x257)
            <br /> Allowed formats: JPEG/JPG/PNG
          </Typography>
          {error && <Box color="red">{error}</Box>}
        </Box>
      )}
    </>
  );
};

export default WithHeader(CoverImage, "Thumbnail *");
